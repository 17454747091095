import tippy from 'tippy.js';

if (window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.body.classList.add('dark');
}

for (let note of document.querySelectorAll('mark.note')) {
    let comment = note.querySelector('.comment');

    tippy(note, {
        theme: 'light-border',
        content: comment.innerHTML,
        allowHTML: true,
        interactive: true,
        maxWidth: 400,
    });

    comment.remove();
}